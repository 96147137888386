.mediaEditDialog {
  input[type=text] {
    margin-top: 24px;
    width: 310px;
    background: #F3F3F3;
    border: none;
    border-bottom: 1px solid #2F3D4A;
    font: normal normal normal 16px/20px Meiryo UI;
    color: #161616;
    padding: 10px;
  }
  .MuiDialogActions-root {
    margin:0 20px 24px 0;
  }
}