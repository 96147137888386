@import "valiable";

.mediaDialogBig {
  .MuiToolbar-root {
    border-bottom: 2px solid $メインカラー;
    margin:0 25px;
    padding: 0;
    .MuiTypography-h6 {
      margin-left:0;
    }
  }

  .MuiDialogContent-root {
    max-width:1545px;
    padding: 20px 25px 14px 25px;
  }
  .selects{
    width: 1310px;
    border: 1px solid $ボーダーカラー;

    .selectAll {
      margin: 0;
      padding: 10px;
      background: rgba(38, 193, 201, 0.3);

    }
    .items {
      padding: 10px 15px;
      .item {
        margin:0;
        width: 425px;
      }
    }
  }

  .selected {
    width: 1310px;
    margin-top: 15px;
    border: 1px solid $ボーダーカラー;
    display: flex;
    justify-content: space-between;
    .selectedlist {
      padding:8px 13px;
      span.clear {
        color: #02f;
        cursor: pointer;
        margin-left: 20px;
      }
      div {
        margin-top:10px;
        span {
          border-radius: 20px;
          display: inline-block;
          background-color: #ccc;
          padding: 5px 15px;
          margin-right: 10px;
          margin-bottom: 10px;
          white-space: nowrap;
        }
      }
    }
    .selectedresult{
      border-left: 1px solid $ボーダーカラー;
      padding:20px 24px;
      position: relative;
      span {
        position: absolute;
        &.count {
          right: 24px;
        }
      }
      button {
        margin-top:50px;
        width: 170px;
        height: 90px;
        border: none;
        border-radius: 5px;
        background: $アクセントカラー;
        font-family: Meiryo;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        &:hover {
          background: lighten($アクセントカラー,5%);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
  
}