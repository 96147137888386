@import "valiable";

.fileData {
  border-bottom: 2px solid #DBE2E8;
  padding-bottom:80px;
  margin-bottom:80px;

  &:last-child {
    border-bottom: none;
  }

  display: flex;
  flex-wrap: wrap;
  //justify-content:space-between;

  &>div {
    max-width:50%;
  }

  .thumbnail {
    display: flex;
    align-items: center;
    img {
      width:320px;
      height: 240px;
      object-fit: contain;
    }
  }
  .filename {
    display: flex;
    justify-content: space-between;

  }

  .nodownload{
    display: flex;
    align-items: center;
    img {
      width:263px;
      height: 198px;
      object-fit: contain;
    }
  }
  .text {
    margin-left:30px;
  }
  h4 {
    margin-bottom: 5px;
  }
  div:nth-child(3) {
    margin-right: 60px;
  }
  textarea {
    background: $入力欄カラー;
    border:none;
    border-bottom:1px solid $文字;
    color: #161616;
    padding: 10px;
    width: 680px;
    max-width:100%;
    min-height: 250px;
  }
  .buttons {
    width:100%;
    max-width:100%;
    margin-top:20px;
    button {
      margin-left:20px;
    }
  }

}

.fileDataView {
  img {
    width:100%;
  }
  //&.dialog {
    .thumbnail {
      div {
        position: relative;
        img {
          width:auto;
          height: 400px;
          display: block;
          margin-bottom:.5em;
        }
      }
      .overflow {
        background:#c9c9c9 url(../public/img/nodownload.svg) 50% 50% no-repeat;
        background-size: contain;
        position:absolute;
        top:0;
        opacity: 0.7;
      }
    }
  //}
  .description {
    white-space: pre-wrap;
  }
  .data {
    margin-top:1em;
    .filename {
      margin-bottom:.5em;
    }
    .buttons {
      display: flex;
      a,button {
        margin-right: 16px;
      }
    }
  }
  margin-bottom:100px;
  &:last-child {
    margin-bottom:0;
  }
  &.dialog {
    &:last-child {
      margin-bottom:5px;
    }
  }
}