@import "valiable";

button.my {
  min-width: 100px;
  height: 40px;
  margin-left:20px;
  font: normal normal bold 16px/40px Meiryo;
  background-color: $アクセントカラー;
  &:hover {
    background-color: lighten($アクセントカラー, 5%);
  }
  &.reset {
    background-color: transparent;
    color: $淡文字;
    border:1px solid $ボーダーカラー;
    &:hover {
      background-color: transparent;
    }
  }
  &.select {
    width:70px;
    height:30px;
    background-color: #fff;
    color:$淡文字;
    font-size:14px;
    padding:0;
    margin-left:30px;
    min-width: 70px;
    
    .MuiButton-endIcon {
      margin-left: 0;
    }
  }
}


button.myclose {
  width: 40px;
  min-width: 40px;
  height: 40px;
  font: normal normal bold 15px/40px Meiryo;
  box-shadow: none;
  border-radius: 5px;
  padding:0;
  color: #fff;
  background-color: #2A333C;
  span {
    margin-right: 0;
    svg.MuiSvgIcon-root {
      font-size:35px;
    }
  }
  &:hover {
    background-color: lighten(#2A333C,10);
  }
}
button.myback {
  min-width: 100px;
  height: 40px;
  font: normal normal bold 15px/40px Meiryo;
  border-radius: 20px;
  color: $文字;
  background-color: #ffffff;
  span {
    margin-right: 0;
    svg.MuiSvgIcon-root {
    font-size:35px;
    }
  }
  &:hover {
    background-color: $サブカラー;
  }
}
button.myedit {
  min-width: 100px;
  height: 40px;
  font: normal normal bold 15px/40px Meiryo;
  border-radius: 20px;
  color: #ffffff;
  background-color: #4D4F5C;
  span {
    margin-right: 0;
    svg.MuiSvgIcon-root {
    font-size:35px;
    }
  }
  &:hover {
    background-color: lighten(#4D4F5C,15%);
  }
}

.my {
  .MuiSelect-select.MuiSelect-filled {
    padding-top: 14px;

  }
  .MuiFilledInput-root {
    .MuiFilledInput-input {
      padding-top: 14px;
      //max-width:80px;
    }
  }
  &.selectListType {
    .MuiSelect-select {
      display: flex;
    }
  }
}

