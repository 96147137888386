.main {
  position: absolute;
  top:80px;
  left:300px;
  width: calc(100vw - 300px);
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding:30px 40px;

  @media print {
    top: 0;
    left: 0;
    width: 1540px;
    height: auto;
    overflow: visible;
    padding: 0;
  }

}