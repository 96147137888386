.fileDropzone {
  cursor: pointer;
  margin-top:30px;

  text-align: center;
  line-height: 48px;
  width:400px;
  background-color: #DBE2E8 ;
  border-radius: 5px;

  p {
    display: block;
    padding: 20px;
    position: relative;
    border-radius: 5px;

    &.dropArea {
      border:blue 5px dashed;
    }
  }

  span {
    white-space: nowrap;
    font-size:16px;
  }

  :hover {
    background-color: #eee;
  }

}