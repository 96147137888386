.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  form {
    background-color: #eee;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px #00000038;

    h1 {
      text-align: center;
      font: normal normal bold 26px/39px Meiryo;
    }
    table {
      tr{
        th {
          text-align: end;
          padding-right: 10px;
          font: normal normal normal 20px/25px Meiryo UI;
        }
        td {
          padding:5px 0;
        }
      }
      input {
        width: 300px;
        font: normal normal normal 20px/25px Meiryo UI;
      }
    }
    .msg {
      text-align: center;
      .notice {
        font: normal normal normal 20px/25px Meiryo UI;
        color:#c30;
      }
    }
    div {
      margin:10px;
      input {
        width:100%;
        font: normal normal normal 20px/25px Meiryo UI;
        height: 50px;
        background-color: #fff;
        border-radius: 30px;
        border:1px solid #333;
        
        &:hover {
          box-shadow: 0px 3px 5px #00000038;
        }
        &:disabled {
          border:1px solid #ccc;
          box-shadow: none;
        }
      }
    }
  }
}