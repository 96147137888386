@import "valiable";

.company {
  .loading {
    display: flex;
    min-height: 50vh;
    justify-content: center;
    align-items: center;

  }

  &>.date {
    font: normal normal bold 20px/30px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    color: $文字;

    &.disabled {
      color: #DBE2E8;
    }

    @media print {
      display: none;
    }
  }

  &>.title {
    @media print {
      display: none;
    }

    width: 970px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    font: normal normal bold 24px/60px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    overflow: hidden;
    color: $文字;

    &.disabled {
      color: #DBE2E8;
    }
  }

  .tabs {
    @media print {
      display: none;
    }

    margin-top: 20px;
    display: flex;

    .tab {
      width: 250px;
      height: 60px;
      box-shadow: 0px 3px 5px #00000038;
      border-radius: 10px 10px 0px 0px;
      margin-right: 15px;
      background-color: #DBE2E8;
      text-align: center;
      font: normal normal bold 20px/60px Meiryo;
      color: #B2BAC2;

      &.preview {
        margin-left: auto;
        margin-right: 0;
      }

      &.active {
        background-color: $メインカラー;
        color: #FFFFFF;

        &.preview {
          background-color: $アクセントカラー;
        }
      }
    }
  }

  .mainArea {
    position: relative;
    padding: 40px;
    border-top: 5px solid $メインカラー;

    .fileDataView .thumbnail div img {
      max-height: 400px;
      height: auto;
    }

    &.preview {
      border-top: 5px solid $アクセントカラー;
      padding: 32px 50px;

      .buttonArea {
        @media print {
          display: none;
        }

        position: absolute;
        right:28px;
        top:32px;

        button {

          background-color: #DBE2E8;
          border-radius: 5px;
          border: none;
          color: #2F3D4A;
          font: normal normal bold 20px/30px Meiryo;
          cursor: pointer;
          margin-bottom: 10px;

          width: 130px;
          height: 48px;
          margin-left: 20px;

          &:hover{
            background-color: #DBE2E880;
            box-shadow: 0px 2px 2px #00000038;
          }
    
        }
      }

      .titleArea {
        padding: 0;
        margin-right: 300px;

        .title {
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          box-shadow: none;
          width: 100%;
          height: auto;
          font: normal normal bold 24px/36px Meiryo;
        }

        .date {
          padding: 0;
          font: normal normal bold 16px/24px Meiryo;
        }
      }

      .dataArea {
        position: relative;

        dl {
          display: flex;
          flex-wrap: wrap;

          dt,
          dd {
            font: normal normal normal 18px/27px Meiryo;
            margin: 0 0 7px 0;
          }

          dt {
            width: 208px;
            position: relative;

            &::after {
              content: ':';
              text-align: right;
              position: absolute;
              display: block;
              right: 3px;
              top: 0;
            }

            button {
              position:  absolute;
              right: 20px;
              width: 60px;
              height: 23px;
              border:none;
              border-radius: 11.5px;
              background: #dbe2e8;
              
              font-weight: normal;
              font-size: 14px;
              color: #2f3d4a;
              &.active {
                color:#fff;
                background-color: $アクセントカラー;
              }
              &:hover {
                box-shadow: 0px 2px 2px #00000038;
              }
            }
          }

          dd {
            width: calc(100% - 208px);
            padding-left: 5px;

            &.multiline {
              white-space: pre-wrap;
            }

            span {
              white-space: nowrap;
              margin-right: 1em;
            }
          }
        }

        #dataAreaCheck {
          display: none;
        }

        #dataAreaCheck+dl {
          max-height: 27px;
          overflow: hidden;
          transition: max-height .5s;

          @media print {
            max-height: 20000px;
          }
        }

        #dataAreaCheck:checked+dl {
          height: auto;
          max-height: 2000px;

          @media print {
            max-height: 20000px;
          }
        }

        label {
          display: block;
          height: 30px;
          position: relative;

          &::after {
            display: block;
            position: absolute;
            bottom: 10px;
            margin: 0 50%;
            content: "";
            width: 15px;
            height: 15px;
            border-top: 2px solid #707070;
            border-left: 2px solid #707070;
            transform: rotate(225deg);
            transition: transform .3s;
            z-index: 50;

            @media print {
              transform: rotate(45deg);
            }
          }
        }

        #dataAreaCheck:checked+dl+label::after {
          transform: rotate(45deg);
        }

        &::after {
          display: block;
          position: absolute;
          bottom: 0;
          content: "";
          height: 10px;
          width: calc(100% + 100px);
          display: block;
          border-bottom: 2px solid #DBE2E8;
          background-color: #fff;
          margin: 0 -50px 0 -50px;
          z-index: 10;
        }

        margin-bottom: 50px;
        padding-bottom: 10px;

      }

      .detailArea {
        position: relative;

        .textArea {
          //float: left;
          font: normal normal normal 16px/24px Meiryo;
          white-space: pre-wrap;
          //width: calc(100% - 740px);
          padding-bottom: 100px;

          h3 {
            font-family: Meiryo;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            text-align: left;
            color: #4d4f5c;
            margin-top:66px;
            margin-bottom: 5px;
          }
          div {
            font-family: Meiryo;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #4d4f5c;

            &.history {
              margin-bottom:12px;
              display: flex;
              span {
                display: inline-block;
                &:nth-child(1) {
                  width:56px;
                  text-align: right;
                }
                &:nth-child(2) {
                  width:56px;
                  text-align: right;
                }
                &:nth-child(3) {
                  margin-left:20px;
                }
              }
            }
          }
        }

        /*
        .imageArea {
          float: right;
          width: 650px;
        }
        */
      }
    }

    background: #FFFFFF;
    max-height: calc(100vh - 320px);

    @media print {
      max-height: none;
    }

    overflow-y: auto;

    @media print {
      overflow-y: visible;
    }

    p {
      margin: 0;
      color: $文字;
      font: normal normal normal 20px/30px Meiryo;
    }

    dl {
      margin-top: 30px;

      dt {
        text-indent: none;
        margin: 0;
        color: $文字;
        font: normal normal bold 20px/30px Meiryo;
        position: relative;

        &.required {
          &::after {
            content: '必須';
            display: inline-block;
            text-align: center;
            font: normal normal bold 14px/22px Meiryo;
            color: #FFFFFF;
            background: #FF7272 0% 0% no-repeat padding-box;
            border-radius: 17px;
            width: 50px;
            height: 22px;
            margin-left: 20px;
            position: relative;
            top: -3px;
          }
        }
      }

      dd {
        text-indent: none;
        margin: 5px 0 50px 0;
        color: $文字;
        font: normal normal normal 20px/30px Meiryo;

        .flex {
          display: flex;
        }

        label {
          span {
            display: block;
            font-size: 12px;
            letter-spacing: 0.03em;
          }

          margin-bottom:12px;

          &.label {
            display: block;
            margin-bottom: 12px;
          }
        }

        .MuiFormGroup-root {
          label {
            margin-bottom: 0;

            span {
              display: flex;

              &.MuiTouchRipple-root {
                display: block;
              }
            }
          }
        }

        .select {
          width: 100px;

          &.pref {
            width: 175px;
          }

          .MuiFilledInput-root {
            padding-top: 0;
            border-radius: 0;
          }
        }

        .companyEdit {
          .companyEditButtons {
            margin-top: 30px;
            margin-bottom: 15px;
            width: 400px;
            position: relative;

            button {
              width: 80px;
              height: 35px;
              line-height: 35px;
              border-radius: 5px;
              border: none;
              padding: 0;

              &:hover {
                opacity: .7;

                &:disabled {
                  opacity: 1.0;
                }
              }

              svg {
                vertical-align: top;
                margin-top: 5px;
              }

              &.add {
                background: #f88b5e;
                margin-right: 15px;
                color: #fff;
              }

              &.edit {
                background: #dbe2e8;
              }

              &.delete {
                background: #dbe2e8;
                border: 1px solid #707070;

                &:disabled {
                  border: 1px solid #dbe2e8;
                }
              }

              &:last-child {
                position: absolute;
                right: 0;
              }
            }

          }

          .companyEditList {
            overflow-y: auto;
            width: 400px;
            height: 150px;
            background: #fff;
            border: 1px solid #dbe2e8;
            box-shadow: 0 3px 5px 0px #ccc inset;

            ul {
              font-size: 16px;
              color: #4d4f5c;
              display: block;
              list-style: none;
              padding: 0 10px;

              li {
                margin: 0;
                padding: 3px 10px;
                text-indent: 0;
                cursor: pointer;

                &.selected {
                  background-color: #dbe2e8;
                }

              }
            }

          }
        }

        input.company {
          background: $入力欄カラー;
          border: none;
          border-bottom: 1px solid $文字;
          font: normal normal normal 16px/20px Meiryo UI;
          color: #161616;
          padding: 10px;

          &[type=text] {
            width: 720px;
          }

          &.input {
            width: 288px;
          }

          &.zip {
            width: 100px;
          }

          &.addr {
            width: 720px;
          }

          &.stake {
            width: 60px;
          }
        }

        button.zip {
          width: 220px;
          height: 35px;
          line-height: 35px;
          border-radius: 5px;
          background: #dbe2e8;
          border: none;
          padding: 0;

          &:hover {
            opacity: .7;

            &:disabled {
              opacity: 1.0;
            }
          }
        }

        button.company {
          background-color: #DBE2E8;
          border-radius: 5px;
          width: 100px;
          height: 48px;
          border: none;
          color: $文字;
          font: normal normal bold 20px/30px Meiryo;
          cursor: pointer;
          margin-bottom: 10px;

          &:hover {
            background-color: #DBE2E880;
            box-shadow: 0px 2px 2px #00000038;

          }
        }

        textarea {
          background: $入力欄カラー;
          border: none;
          border-bottom: 1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height: 300px;
        }

        .list {
          background: $入力欄カラー;
          border: none;
          border-bottom: 1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height: 80px;
        }


        &.editor {
          display: flex;

          input.company {
            width: 310px;
            margin-right: 33px;
          }
        }

      }
    }

    &.companyHistory {
      p {
        margin-bottom: 5px;
      }

      input.company {
        background: $入力欄カラー;
        border: none;
        border-bottom: 1px solid $文字;
        font: normal normal normal 16px/20px Meiryo UI;
        color: #161616;
        padding: 10px;

        &[type=text] {
          width: 720px;
        }

        &.input {
          width: 288px;
        }

        &.zip {
          width: 100px;
        }

        &.addr {
          width: 720px;
        }

        &.stake {
          width: 60px;
        }
      }

      .content {
        width: 720px;
        text-align: right;
        color: #8d8d8d;
        font-size: 14px;
        letter-spacing: 0.01em;
        margin-top: 12px;

        textarea {
          background: $入力欄カラー;
          border: none;
          border-bottom: 1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height: 80px;
          margin-top: 6px;
          margin-bottom: 12px;
        }
      }

      button.preview {
        width: 180px;
        height: 35px;
        border-radius: 5px;
        border: none;
        background: #dbe2e8;

        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #2f3d4a;

        &:hover {
          opacity: .7;
        }

      }


      .companyEdit {
        .companyEditButtons {
          margin-top: 30px;
          margin-bottom: 15px;
          position: relative;

          button {
            width: 80px;
            height: 35px;
            line-height: 35px;
            border-radius: 5px;
            border: none;
            padding: 0;

            &:hover {
              opacity: .7;

              &:disabled {
                opacity: 1.0;
              }
            }

            svg {
              vertical-align: top;
              margin-top: 5px;
            }

            &.add {
              background: #f88b5e;
              margin-right: 15px;
              color: #fff;
            }

            &.edit {
              background: #dbe2e8;
            }

            &.delete {
              background: #dbe2e8;
              border: 1px solid #707070;

              &:disabled {
                border: 1px solid #dbe2e8;
              }
            }

          }

        }

        .companyEditList {
          overflow-y: auto;
          width: 400px;
          height: 150px;
          background: #fff;
          border: 1px solid #dbe2e8;
          box-shadow: 0 3px 5px 0px #ccc inset;

          ul {
            font-size: 16px;
            color: #4d4f5c;
            display: block;
            list-style: none;
            padding: 0 10px;

            li {
              margin: 0;
              padding: 3px 10px;
              text-indent: 0;
              cursor: pointer;

              &.selected {
                background-color: #dbe2e8;
              }

            }
          }

        }
      }

      .companyHistoryList {
        .tableHead {
          display: flex;

          div:nth-child(1) {
            width: 33px;
          }

          div:nth-child(2) {
            width: 60px;
            text-align: center;
          }

          div:nth-child(3) {
            width: 44px;
            text-align: center;
          }

          div:nth-child(4) {
            width: 617px;
            text-align: left;
            padding-left: 10px;
          }

          width:754px;
          font-family: Meiryo;
          font-weight: bold;
          font-size: 16px;
          color: #fff;
          line-height: 35px;

          background: $メインカラー;
        }

        .MuiDialogContent-root {
          position: relative;
          padding: 0 0 0 0;
          width: 780px;
          height: 360px;

          &::after {
            content: "";
            display: block;
            position: sticky;
            bottom: 0;
            background-color: $ボーダーカラー;
            height: 1px;
            width: 754px;
          }
        }

        .selects {
          width: 754px;
          border: 1px solid $ボーダーカラー;
          border-bottom: none;



          .items {
            padding: 0;

            .itemArea {
              border-bottom: .5px solid #dbe2e8;
              border-top: .5px solid #dbe2e8;
              display: flex;
              justify-content: space-between;
              background-color: #fff;

              &:hover {
                background: #f3f3f3;
              }

              &:has(.drag) {
                border-top: 1px solid #dbe2e8;
                background: #f3f3f3;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              }

              &.selected {
                background: #f3f3f3;
              }

              div:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33px;
                cursor: move;
                border-right: 1px solid #dbe2e8;
              }

              div:nth-child(2) {
                width: 60px;
                text-align: center;
                padding: 5px;
                border-right: 1px solid #dbe2e8;
              }

              div:nth-child(3) {
                width: 44px;
                text-align: center;
                padding: 5px;
                border-right: 1px solid #dbe2e8;
              }

              div:nth-child(4) {
                width: 517px;
                padding: 5px;
                white-space: pre-wrap;
                border-right: 1px solid #dbe2e8;
              }

              div:nth-child(5) {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100px;
                padding: 5px;
              }


              button {
                width: 35px;
                height: 32px;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                font-size: 16px;
                text-align: center;
                color: #2f3d4a;
                cursor: pointer;

                svg {
                  vertical-align: middle;
                }

                &:hover {
                  //background: lighten($サブカラー,10%);
                  //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                }
              }
            }
          }
        }


      }
    }



    .buttons {
      text-align: right;


      button {
        background: $アクセントカラー;
        border-radius: 5px;
        width: 160px;
        color: #FFFFFF;
        height: 48px;
        border: none;
        font: normal normal bold 20px/30px Meiryo;
        cursor: pointer;
        margin-bottom: 10px;

        span.num {
          display: inline-block;
          background: $アクセントカラー 0% 0% no-repeat padding-box;
          border-radius: 15px;

          font: normal normal normal 14px/48px Meiryo;
          line-height: 30px;
          text-align: center;
          color: #FFFFFF;
          border: 1px solid #fff;
          width: 30px;
          height: 30px;
          position: relative;
          top: 0;
          left: 10px;
        }

        &:hover {
          background-color: #DBE2E880;
          box-shadow: 0px 2px 2px #00000038;

        }

        &.editTarget {
          position: absolute;
          top: 0;
          left: 170px;
          width: 96px;
          height: 32px;
          border-radius: 5px;
          background: #dbe2e8;
          border: 1px solid #707070;
          font-size: 20px;
        }


        &:hover {
          background-color: lighten($アクセントカラー, 5%);
        }

        &:disabled {
          background-color: desaturate($アクセントカラー, 50%);
          box-shadow: none;
        }
      }
      &.groupcompanies {
        display: flex;

        button {
          width: 400px;
        }
      }

    }

  }



  .tableBase {
    background-color: #ffffff;
    box-shadow: 0px 3px 5px #00000038;
    position: relative;

    padding: 18px;

    &.nopadding {
      padding: 0;
      z-index: 10;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background: $メインカラー;
        color: #ffffff;
        font: normal normal bold 18px/27px Meiryo;
        text-align: left;

        tr {
          table-layout: fixed;

          th {
            padding: 3px 13px;

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              width: 150px;
            }

            &:nth-child(3) {
              width: 250px;
            }
          }
        }
      }

      tbody {
        border: 1px solid #B2BAC2;

        tr {
          table-layout: fixed;
          background: #F1F4F8;

          &:nth-child(2n) {
            background: #FFFFFF;
          }

          cursor: pointer;

          &:hover {
            box-shadow: 0px 3px 5px #00000038;
            position: relative;
            z-index: 100;
          }

          td {
            padding: 13px;
            color: $淡文字;
          }
        }
      }
    }

    .selects {
      position: absolute;
      right: 18px;
      top: 23px;

      .MuiFormControl-root {
        margin-left: 10px;
      }

    }
  }

  .publicationListBase {
    position: relative;
    padding: 0 50px;

    .mediaHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      padding-left: 25px;

      button {
        margin-left: 10px;
        background-color: #fff;
        border-radius: 5px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        color: $文字;

        &:hover {
          opacity: .7;
        }

        &:disabled {
          color: #2f3d4a;
          opacity: .5;
        }
      }
    }

    .listMedia {
      background-color: #fff;
      border: 1px solid $ボーダーカラー;
      margin-bottom: 17px;

      .titleArea {
        display: flex;
        align-items: center;
        border-bottom: 3px solid #26c1c9;
        padding: 10px 20px;

        .iconArea {
          display: flex;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .selectArea {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 56px;
        background: #bdecef;

        .message {
          padding-left: 17px;

          .selectall {
            margin-left: 20px;
            color: #00f;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: #fff;
            }
          }

        }

        .addList {
          width: 255px;
          height: 32px;
          border-radius: 5px;
          background: #fff;
          border: 1px solid #707070;
          margin-right: 220px;
          margin-left: 15px;

          font-size: 16px;
          color: $文字;

          svg {
            margin-right: 10px;
            margin-top: 3px;
          }

          &:hover {
            box-shadow: 0px 2px 2px #00000038;
          }
        }

      }

      .listMediaPub {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 54px;
        height: 56px;

        &:hover {
          background-color: #f1f4f8;
        }

        border-bottom: 1px solid #dbe2e8;

        &:last-child {
          border-bottom: none;
        }

        .buttonArea {
          display: flex;
          align-items: center;

          .dataArea {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-left: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
            height: 56px;
          }

          button {
            &.data {
              width: 150px;
              height: 32px;
              border-radius: 5px;
              background: #dbe2e8;

              font-family: Meiryo;
              font-weight: bold;
              font-size: 16px;
              text-align: center;
              color: $文字;
              margin-right: 15px;
              margin-left: 15px;

              span.num {
                display: inline-block;
                background: $アクセントカラー 0% 0% no-repeat padding-box;
                border-radius: 15px;

                font: normal normal normal 14px/48px Meiryo;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF;
                border: 1px solid #fff;
                width: 30px;
                height: 30px;
                position: relative;
                top: 0;
                left: 10px;
              }

              &:hover {
                background-color: #DBE2E880;
                box-shadow: 0px 2px 2px #00000038;
              }

            }

            &.addList {
              width: 255px;
              height: 32px;
              border-radius: 5px;
              background: #fff;
              border: 1px solid #707070;
              margin-right: 20px;
              margin-left: 15px;

              font-size: 16px;
              color: $文字;

              svg {
                margin-right: 10px;
                margin-top: 3px;
              }

              &:hover {
                box-shadow: 0px 2px 2px #00000038;
              }
            }

            &.download {
              width: 188px;
              height: 32px;
              border-radius: 5px;
              background: $アクセントカラー;

              font-size: 16px;
              color: #fff;

              svg {
                margin-right: 10px;
              }

              &:hover {
                box-shadow: 0px 2px 2px #00000038;
              }
            }
          }
        }
      }
    }
  }

  .searchConditions {
    display: flex;
    flex-wrap: wrap;

    dt {
      width: 240px;
      background-color: $サブカラー;
      font-size: 20px;
      padding: 15px 20px;
      border-bottom: 1px solid $ボーダーカラー;
    }

    dd {
      width: calc(100% - 240px);
      margin: 0;
      padding: 15px 20px;
      //background-color: #ccc;
      border-bottom: 1px solid $ボーダーカラー;

      .history {
        display: inline-block;
        text-decoration: underline;
        font-size: 14px;
        color: $文字;
        margin-top: 10px;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    margin-block-end:0;

    button.comapnySelect {
      background: #dbe2e8;
      color: #2f3d4a;
      height: 35px;
      font-size: 14px;
      border-radius: 17.5px;
      margin-right: 10px;
      margin-bottom: 15px;
      font-weight: bold;

      &.active {
        background: rgba(38, 193, 201, .3);
        color: #0999a1;
      }

    }
  }

  .companyListItem {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-around;

    .image {
      width: 420px;
      height: 450px;
      margin-left: 35px;
      margin-bottom: 25px;
      position: relative;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      .category {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, .5);
        font-size: 14px;
        line-height: 30px;
        padding: 0 10px;
        color: #2f3d4a;
      }

      .imageArea {

        position: relative;
        width: 420px;
        height: 200px;

        button {
          width: 210px;
          height: 32px;
          border-radius: 5px;
          border: none;
          background: #dbe2e8;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 100;
          font-size: 16px;

          &:hover {
            background-color: lighten(#dbe2e8, 5%);
            box-shadow: 0px 2px 2px #00000038;
          }
        }

        img {
          width: 420px;
          height: 200px;
          object-fit: contain;
        }
      }

      button {
        &.data {
          width: 120px;
          height: 40px;
          position: absolute;
          right: 10px;
          top: 290px;
          border-radius: 5px;
          background: #dbe2e8;

          font-family: Meiryo;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          color: $文字;
          margin-right: 10px;
          margin-left: 10px;
          padding: 0;

          span.num {
            display: inline-block;
            background: $アクセントカラー 0% 0% no-repeat padding-box;
            border-radius: 15px;

            font: normal normal normal 14px/48px Meiryo;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            border: 1px solid #fff;
            width: 24px;
            height: 24px;
            position: absolute;
            top: -10px;
            left: 105px;
          }

          &:hover {
            background-color: #DBE2E880;
            box-shadow: 0px 2px 2px #00000038;
          }

        }
      }

      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2f3d4a;
        padding: 0 20px;
        margin-top: 16px;
      }

      .title_en {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #2f3d4a;
        padding: 0 20px;
      }

      dl {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        border-top: 1px solid #b2bac2;
        width: 100%;

        dt {
          width: 71px;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #2f3d4a;
          background: rgba(38, 193, 201, .3);

          &:nth-of-type(1) {
            padding-top: 5px;
          }

          &:nth-of-type(3) {
            padding-bottom: 5px;
          }
        }

        dd {
          width: calc(100% - 71px);
          margin: 0;
          line-height: 30px;
          font-size: 14px;
          color: #2f3d4a;
          padding: 0 10px;

          &:nth-of-type(1) {
            padding-top: 5px;
          }

          &:nth-of-type(3) {
            padding-bottom: 5px;
          }
        }
      }
    }

  }

  .companyMain2 {
    .sample {
      ul {
        width: 500px;
        padding: 20px 22px;
        background: rgba(38, 193, 201, .3);
        display: flex;
        flex-wrap: wrap;

        li {
          font-weight: bold;
          font-size: 16px;
          line-height: 27px;
          color: #2f3d4a;
          list-style: none;

          &::before {
            content: '・';
          }

          &:nth-child(2n) {
            width: 194px;
          }

          &:nth-child(2n - 1) {
            width: 262px;
          }
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        color: #2f3d4a;
      }
    }

    .buttons {
      margin-top: 70px;
    }

    .companyMain2Data {
      .data {
        display: flex;
        flex-wrap: wrap;
        //justify-content:space-between;
        align-items: end;

        div:first-child {
          margin-right: 60px;
        }

        h3 {
          font-weight: bold;
          font-size: 20px;
          color: #2f3d4a;
        }

        .select {
          width: 300px;
          margin-bottom: 13px;

          .MuiFilledInput-root {
            padding-top: 0;
            border-radius: 0;
          }
        }

        h4 {
          font-weight: bold;
          font-size: 16px;
          color: #2f3d4a;
          margin: 5px 0;
        }


        textarea {
          background: $入力欄カラー;
          border: none;
          border-bottom: 1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 680px;
          max-width: 100%;
          min-height: 250px;
        }
      }
    }


  }

}

.companyFile {
  .uploadText {
    margin: 50px -40px 0 -40px;
    padding: 40px;
    border-top: 2px solid #DBE2E8;
    font: normal normal normal 20px/30px Meiryo;

    span {
      font-weight: bold;
    }
  }
}

.companyPreview {
  padding: 20px;
  display: flex;
  width:990px;

  .year {
    background-color: #f44336;
    font-family: 'メイリオ', 'Meiryo', sans-serif;
    width: 150px;
    height: 70px;
    font-size: 30px;
    line-height: 70px;
    color: #fff;
    overflow: hidden;
    border-radius: 38px;
    margin-top: 10px;
    margin-left: 40px;
    text-align: center;
  }

  .month {
    font-family: 'メイリオ', 'Meiryo', sans-serif;
    color: #f44336;
    width: 58px;
    height: 70px;
    font-size: 22px;
    line-height: 70px;
    overflow: hidden;
    border-radius: 0;
    margin-top: 10px;
    margin-left: 7px;
  }

  .content {
    font-family: 'メイリオ', 'Meiryo', sans-serif;
    width: 660px;
    text-align: justify;
    font-size: 30px;
    line-height: 45px;
    color: #333333;
    margin-left: 18px;
    position: relative;

  }

}