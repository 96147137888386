.filesDialog {
  div[role=dialog] {
    width: 1295px;
    max-width: 1295px;

    .MuiDialogContent-root {
      padding: 0;
      overflow-y: hidden;
      display: flex;

      .content {
        display: flex;
        justify-content: space-between;
        position: relative;

        .listClear {
          position: absolute;
          top:20px;
          right: 40px;
          cursor: pointer;
          z-index:200;
          &.withKeep {
            right: 360px;
          }
        }

        .items {
          width: 1255px;
          overflow-y: auto;
          margin: 22px 10px 22px 25px;
          &.haskeep {
            width: 940px;
          }
        }

        .keeps {
          background-color: #F1F4F8;
          width: 320px;
          overflow-y: auto;
          padding: 22px 0;
          position: relative;

          h5 {
            font-family: Meiryo;
            font-weight: bold;
            font-size: 16px;
            margin:0 0 12px 0;
            padding: 0 25px;
          }

          .keepitems {
            height:calc(100% - 140px);
            overflow-y: auto;
            padding: 0 25px;

            .fileDataView {
              background-color: #fff;
              text-align: center;

              .thumbnail {
                div {
                  text-align: center;
                  margin:0;

                  img {
                    display: block;
                    width: auto;
                    height: 150px;
                    width: 100%;
                    display: block;
                    margin: 0 auto 10px auto;
                    object-fit: contain;
                  }
                }
              }

              .data {
                margin-top: 0;
                .buttons {
                  display: flex;
                  justify-content: center;
                  padding-bottom: 10px;
                }
              }
              margin-bottom:16px;
              &:last-child {
                margin-bottom:0;
              }
            }
          }

          .bottomButtons {
            position: absolute;
            width:100%;
            bottom:0;
            text-align: center;
            padding: 0 25px;
            button {
              width:100%;
              margin-bottom: 20px;
              &.MuiButton-outlined {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}