@import "valiable";

.publication {
  .loading {
    display: flex;
    min-height: 50vh;
    justify-content: center;
    align-items: center;

  }

  &>.date {
    font: normal normal bold 20px/30px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    color: $文字;
    &.disabled {
      color: #DBE2E8;
    }
    @media print {
      display: none;
    }
  }
  &>.title {
    @media print {
      display: none;
    }
    width: 970px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    font: normal normal bold 24px/60px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    overflow: hidden;
    color: $文字;
    &.disabled {
      color: #DBE2E8;
    }
  }
  .tabs {
    @media print {
      display: none;
    }
    margin-top: 20px;
    display: flex;
    .tab {
      width: 250px;
      height: 60px;
      box-shadow: 0px 3px 5px #00000038;
      border-radius: 10px 10px 0px 0px;
      margin-right: 15px;
      background-color: #DBE2E8;
      text-align: center;
      font: normal normal bold 20px/60px Meiryo;
      color: #B2BAC2;

      &.preview {
        margin-left: auto;
        margin-right: 0;
      }
      &.active {
        background-color: $メインカラー;
        color: #FFFFFF;
        &.preview {
          background-color: $アクセントカラー;
        }
      }
    }
  }

  .mainArea {
    position: relative;
    padding:40px;
    border-top: 5px solid $メインカラー;
    &.preview {
      border-top: 5px solid $アクセントカラー;
      padding:32px 50px;

      .buttonArea {
        @media print {
          display: none;
        }
        position: absolute;
        right:28px;
        top:32px;

        button {
          width:130px;
          height: 48px;
          margin-left: 20px;
        }
      }
      .titleArea {
        padding:0;
        margin-right:300px;
        .title {
          padding:0;
          background: none;
          border: none;
          border-radius: 0;
          box-shadow: none;
          width:100%;
          height:auto;
          font: normal normal bold 24px/36px Meiryo;
        }
        .date {
          padding:0;
          font: normal normal bold 16px/24px Meiryo;
        }
      }
      .dataArea {
        position: relative;
        dl {
          display: flex;
          flex-wrap: wrap;
          dt , dd{
            font: normal normal normal 18px/27px Meiryo;
            margin: 0 0 7px 0;
          }
          dt {
            width: 108px;
            position: relative;
            &::after {
              content:':';
              text-align: right;
              position: absolute;
              display: block;
              right: 3px;
              top:0;
            }
          }
          dd {
            width: calc(100% - 108px);
            padding-left: 5px;
            
            span {
              white-space:nowrap;
              margin-right: 1em;
            }
          }
        }
        #dataAreaCheck {
          display: none;
        }
        #dataAreaCheck + dl{
          max-height:27px;
          overflow: hidden;
          transition: max-height .5s;
          @media print {
            max-height:1000px;
          }
        }
        #dataAreaCheck:checked + dl{
          height:auto;
          max-height: 500px;
          @media print {
            max-height:1000px;
          }
        }
        label {
          display: block;
          height: 30px;
          position: relative;
          &::after{
            display: block;
            position: absolute;
            bottom:10px;
            margin: 0 50%;
            content: "";
            width:15px;
            height: 15px;
            border-top: 2px solid #707070;
            border-left: 2px solid #707070;
            transform:rotate(225deg);
            transition: transform .3s;
            z-index: 50;
            @media print {
              transform:rotate(45deg);
            }
          }
        }
        #dataAreaCheck:checked + dl + label::after{
          transform:rotate(45deg);
        }
        &::after {
          display: block;
          position: absolute;
          bottom:0;
          content:"";
          height: 10px;
          width: calc(100% + 100px);
          display: block;
          border-bottom: 2px solid #DBE2E8;
          background-color: #fff;
          margin: 0 -50px 0 -50px;
          z-index: 10;
        }
        margin-bottom: 50px;
        padding-bottom: 10px;

      }
      .detailArea {
        position: relative;
        .textArea {
          float:left;
          font: normal normal normal 16px/24px Meiryo;
          white-space:pre-wrap;
          width:calc(100% - 740px);
        }
        .imageArea {
          float:right;
          width: 650px;
        }
      }
    }
    background: #FFFFFF;
    max-height: calc(100vh - 320px);
    @media print {
      max-height: none;
    }
    overflow-y: auto;
    @media print {
      overflow-y: visible;
    }

    p {
      margin:0;
      color: $文字;
      font: normal normal normal 20px/30px Meiryo;
    }
    dl {
      margin-top: 30px;
      dt {
        text-indent: none;
        margin:0;
        color: $文字;
        font: normal normal bold 20px/30px Meiryo;
        position: relative;
        
        &.required {
          &::after {
            content:'必須';
            display: inline-block;
            text-align: center;
            font: normal normal bold 14px/22px Meiryo;
            color: #FFFFFF;
            background: #FF7272 0% 0% no-repeat padding-box;
            border-radius: 17px;
            width: 50px;
            height: 22px;
            margin-left: 20px;
            position: relative;
            top:-3px;
          }
        }
      }
      dd {
        text-indent: none;
        margin:5px 0 50px 0;
        color: $文字;
        font: normal normal normal 20px/30px Meiryo;
        

        input {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          font: normal normal normal 16px/20px Meiryo UI;
          color: #161616;
          padding: 10px;
          &[type=text] {
            width:720px;
          }
        }
        textarea {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height: 300px;
        }
        .list {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
        }

        &.editor {
          display: flex;
          input {
            width:310px;
            margin-right: 33px;
          }
        }

      }
    }

    button {
      background-color: #DBE2E8;
      border-radius: 5px;
      width: 168px;
      height: 48px;
      border:none;
      color: $文字;
      font: normal normal bold 20px/30px Meiryo;
      cursor: pointer;
      margin-bottom: 10px;

      span.num {
        display: inline-block;
        background: $アクセントカラー 0% 0% no-repeat padding-box;
        border-radius: 15px;

        font: normal normal normal 14px/48px Meiryo;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        border: 1px solid #fff;
        width: 30px;
        height: 30px;
        position: relative;
        top:0;
        left:10px;
      }

      &:hover{
        background-color: #DBE2E880;
        box-shadow: 0px 2px 2px #00000038;

      }

      &.editTarget {
        position: absolute;
        top:0;
        left:170px;
        width: 96px;
        height: 32px;
        border-radius: 5px;
        background: #dbe2e8;
        border: 1px solid #707070;
        font-size: 20px;
      }
    }

    .buttons {
      text-align: right;
      button {
        background: $アクセントカラー;
        width: 160px;
        color: #FFFFFF;

        &:hover{
          background-color: lighten($アクセントカラー, 5%);
        }
        &:disabled {
          background-color: desaturate($アクセントカラー, 50%);
          box-shadow: none;
        }
      }
    }

  }



  .tableBase {
    background-color: #ffffff;
    box-shadow: 0px 3px 5px #00000038;
    position: relative;
  }

  .publicationListBase {
    position: relative;
    padding:0 50px;

    .mediaHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      padding-left: 25px;
      button {
        margin-left: 10px;
        background-color: #fff;
        border-radius: 5px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        color: $文字;
        &:hover {
          opacity: .7;
        }
        &:disabled {
          color: #2f3d4a;
          opacity: .5;
        }
      }
    }

    .listMedia {
      background-color: #fff;
      border: 1px solid $ボーダーカラー;
      margin-bottom: 17px;

      .titleArea {
        display: flex;
        align-items: center;
        border-bottom: 3px solid #26c1c9;
        padding:10px 20px;

        .iconArea {
          display: flex ;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      .selectArea {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 56px;
        background: #bdecef;

        .message {
          padding-left: 17px;

          .selectall {
            margin-left:20px;
            color: #00f;
            padding:5px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: #fff;
            }
          }

        }

        .addList {
          width: 255px;
          height: 32px;
          border-radius: 5px;
          background: #fff;
          border: 1px solid #707070;
          margin-right:220px;
          margin-left: 15px;

          font-size: 16px;
          color: $文字;
          svg {
            margin-right: 10px;
            margin-top:3px;
          }
          &:hover{
            box-shadow: 0px 2px 2px #00000038;
          }
        }

      }

      .listMediaPub {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0 12px 0 54px;
        height: 56px;
        &:hover {
          background-color: #f1f4f8;
        }
        border-bottom: 1px solid #dbe2e8;
        &:last-child {
          border-bottom: none;
        }

        .buttonArea {
          display: flex;
          align-items: center;
          .dataArea {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-left: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
            height: 56px;
          }
          button {
            &.data {
              width: 150px;
              height: 32px;
              border-radius: 5px;
              background: #dbe2e8;
              
              font-family: Meiryo;
              font-weight: bold;
              font-size: 16px;
              text-align: center;
              color: $文字;
              margin-right:15px;
              margin-left: 15px;
              
              span.num {
                display: inline-block;
                background: $アクセントカラー 0% 0% no-repeat padding-box;
                border-radius: 15px;

                font: normal normal normal 14px/48px Meiryo;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF;
                border: 1px solid #fff;
                width: 30px;
                height: 30px;
                position: relative;
                top:0;
                left:10px;
              }
              &:hover{
                background-color: #DBE2E880;
                box-shadow: 0px 2px 2px #00000038;
              }

            }

            &.addList {
              width: 255px;
              height: 32px;
              border-radius: 5px;
              background: #fff;
              border: 1px solid #707070;
              margin-right:20px;
              margin-left: 15px;

              font-size: 16px;
              color: $文字;
              svg {
                margin-right: 10px;
                margin-top:3px;
              }
              &:hover{
                box-shadow: 0px 2px 2px #00000038;
              }
            }

            &.download {
              width: 188px;
              height: 32px;
              border-radius: 5px;
              background: $アクセントカラー;

              font-size: 16px;
              color: #fff;

              svg {
                margin-right: 10px;
              }
              &:hover{
                box-shadow: 0px 2px 2px #00000038;
              }
            }
          }
        }
      }
    }
  }

  .searchConditions {
    display:flex;
    flex-wrap: wrap;
    dt {
      width:240px;
      background-color: $サブカラー;
      font-size:20px;
      padding:15px 20px;
      border-bottom: 1px solid $ボーダーカラー;
    }
    dd {
      width:calc(100% - 240px);
      margin:0;
      padding:15px 20px;
      //background-color: #ccc;
      border-bottom: 1px solid $ボーダーカラー;

      .history {
        display: inline-block;
        text-decoration: underline;
        font-size: 14px;
        color: $文字;
        margin-top:10px;
        margin-right: 20px;
        cursor: pointer;
      }

      input {
        background: $入力欄カラー;
        border:none;
        border-bottom:1px solid $文字;
        font: normal normal normal 16px/20px Meiryo UI;
        color: #161616;
        padding: 10px;
        text-align: right;
      }

    }
  }
}

