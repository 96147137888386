.filesListDialog {
  div[role=dialog] {
    width: 1295px;
    max-width: 1295px;

    .MuiDialogContent-root {
      padding: 0;
      height: 600px;
      overflow-y: hidden;
      display: flex;

      .content {
        .titleArea {
          display: flex;
          justify-content: space-between;
          padding: 25px 25px 18px 5px;

          .title {
            font-size: 20px;
            width: calc(100% - (80px + 10px + 180px + 10px + 175px));

            .breadcrumb {
              cursor: pointer;
              padding: 10px 15px;
              margin:0 5px;
              border-radius: 20px;
              &:hover {
                background-color: #dbe2e8;
              }
            }
          }

          .buttons {
            text-align: right;
            //background-color: #00f;
            width: 80px + 10px + 180px + 10px + 175px;

            .buttonDelete {
              margin-right: 10px;
              width: 80px;
            }

            .buttonAddList {
              margin-right: 10px;
              width: 180px;
            }

            .buttonDownload {
              width: 175px;
            }
          }
        }

        .items {
          width: 1295px;
          height: calc(100% - 79px);
          overflow-y: auto;
          margin: 0;

          .itemsHead {
            padding: 26px 26px 5px 26px;
            border-top: 1px solid #dbe2e8;
            border-bottom: 1px solid #dbe2e8;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            position: sticky;
            top: 0;
            z-index:1;
            background-color: #fff;

            .itemsHeadName {
              width: 914px;
            }

            .itemsHeadDate {
              width: 184px;
            }

            .itemsHeadSize {
              width: 145px;
            }

            .itemsHeadPreview {
              display: none;
            }
          }

          &.withPreview {
            .itemsHead {
              .itemsHeadName {
                width: 626px;
              }

              .itemsHeadDate {
                width: 132px;
              }

              .itemsHeadSize {
                width: 165px;
              }

              .itemsHeadPreview {
                width: 320px;
                display: block;
              }
            }
          }

          .itemsItem {
            padding: 8px 26px 8px 26px;
            border-top: 1px solid #dbe2e8;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            cursor: pointer;

            &:hover {
              background: #f1f4f8;
            }
            &:nth-of-type(2){
              border-top: none;
            }
            &.selected {
              background: #f1f4f8;
            }

            .itemsItemName {
              width: 914px;
              svg {
                vertical-align: middle;
              }

            }

            .itemsItemDate {
              width: 184px;
            }

            .itemsItemSize {
              width: 145px;
            }

          }

          &.withPreview {
            .itemsItem {
              width: 949px;
              padding-right: 0;
              .itemsItemName {
                width: 626px;
              }

              .itemsItemDate {
                width: 132px;
              }

              .itemsItemSize {
                width: 165px;
              }
            }
          }
        }

        #preview {
          position: absolute;
          top:250px;
          right:20px;
          width:320px;
          text-align: center;
          img {
            width:300px;
          }
        }
      }
    }
  }
}