@import "valiable";

.header {
  @media print {
    display: none;
  }
  z-index: 100;
  position: relative;
  background-color: $メインカラー;
  height: 80px;
  box-shadow: 0px 3px 5px #00000038;
  
  display:flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font: normal normal bold 26px/39px Meiryo;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 29px;
  }

  .controls {
    display:flex;
    align-items: center;
    margin-right: 24px;

    .downloadList {
      cursor: pointer;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      width: 260px;
      height: 48px;
      font: normal normal bold 20px/30px Meiryo;
      color: #2A333C;
      line-height: 48px;
      padding-left: 20px;
      span {
        display: inline-block;
        background: $アクセントカラー 0% 0% no-repeat padding-box;
        border-radius: 15px;
        font: normal normal normal 14px/21px Meiryo;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        width: 30px;
        height: 30px;
        position: relative;
        top:-3px;
        left:10px;
      }
    }
    .user {
      color: #FFFFFF;
      margin:0 25px 0 50px;
      font: normal normal normal 20px/25px Meiryo UI;
    }
    .signoutButton {
      background-color: #FFFFFF;
      font: normal normal bold 16px/24px Meiryo;
      text-align: center;
      line-height: 30px;
      color: $文字;
      width: 100px;
      height: 30px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 3px 5px #00000038;
      }
    }
  }
}