@import "valiable";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #F1F4F8;

  @media print {
    zoom: 0.5;
    -webkit-print-color-adjust: exact;
  }
}

.pager {
  ul {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1600px) {
      justify-content: left;
    }
    padding-left:0;


    overflow-x: auto;
    overflow-y: hidden;

    li {
      display: inline-block;

      a,
      span {
        display: inline-block;
        text-decoration: none;
        width: 32px;
        height: 32px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D7DAE2;
        border-right: none;
        opacity: 1;
        text-align: center;
        font: normal normal normal 14px/20px Meiryo;
        color: $文字;
        line-height: 32px;
        cursor: pointer;

        &:hover {
          background: lighten($アクセントカラー, 25%) 0% 0% no-repeat padding-box;
        }

        &.current {
          color: #FFFFFF;
          background: $アクセントカラー 0% 0% no-repeat padding-box;
        }
      }

      &.withIcon {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:first-child {

        a,
        span {
          border-radius: 5px 0 0 5px;
        }
      }

      &:last-child {

        a,
        span {
          border-radius: 0 5px 5px 0;
          border-right: 1px solid #D7DAE2;
        }
      }
    }
    
  }
}

.bottomSpace {
  margin-bottom: 40px;
}