@import "valiable";

.pubMediaDialogEditable {
  .MuiPaper-root {
    width:800px;
  }
  .MuiToolbar-root {
    border-bottom: 2px solid $メインカラー;
    margin:10px 25px 0 25px;
    padding: 0;
    .MuiTypography-h6 {
      margin-left:0;
    }
  }

  .dialgHead {
    margin:16px 25px 0 25px;
    .description {
      font-size: 18px;
      margin-bottom:16px;
    }
    .tableHead{
      display: flex;
      div:nth-child(1) {
        width:50px;
      }
      div:nth-child(2) {
        width:36px;
        border-left: 1px solid #dbe2e8;
      }
      div:nth-child(3) {
        border-left: 1px solid #dbe2e8;
        padding:5px 16px;
      }
      background: $メインカラー;
      font-size: 16px;
      color: #fff;
    }
    .tableHeadAll{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $サブカラー;
      div {
        padding:17px;
        span {
          margin-left:20px;
          color: #00f;
          padding:5px;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background-color: #fff;
          }
        }
      }
      button {
        margin-right: 12px;
        width: 160px;
        height: 32px;
        border:none;
        border-radius: 5px;
        background: #fff;
        font-size: 16px;
        line-height: 32px;
        color: $淡文字;
        cursor: pointer;
        svg {
          margin-right:15px;
          vertical-align: middle;
          width:17px;
          margin-bottom: 4px;
        }
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }

  .MuiDialogContent-root {
    position: relative;
    padding: 0 0 0 25px;
    &::after {
      content: "";
      display:block;
      position:sticky;
      bottom:0;
      background-color: $ボーダーカラー;
      height:1px;
      width:750px;
    }
  }
  .selects{
    width:750px;
    border: 1px solid $ボーダーカラー;
    border-bottom: none;



    .items {
      padding: 0;
      .itemArea {
        border-bottom: .5px solid #dbe2e8;
        border-top: .5px solid #dbe2e8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        &:hover {
          background: #f3f3f3;
        }
        &:has(.drag) {
          border-top: 1px solid #dbe2e8;
          background: #f3f3f3;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }

        div:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width:50px;
          cursor: move;
        }
        .item {
          margin:0;
          width: calc(100% - 50px - 100px - 100px);
          background-image: linear-gradient(90deg, #dbe2e8 0%, #dbe2e8 1px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 36px, #dbe2e8 36px, #dbe2e8 37px, rgba(0,0,0,0) 37px, rgba(0,0,0,0) 100%);
          .css-gwktup-MuiButtonBase-root-MuiCheckbox-root {
            padding-left:7px;
            padding-right:7px;
          }
          .MuiFormControlLabel-label {
            margin-left:14px;
          }
        }

        button {
          width: 88px;
          height: 32px;
          border-radius: 5px;
          background: #fff;
          border: 1px solid #707070;
          margin-right: 12px;
          font-size: 16px;
          text-align: center;
          color: #2f3d4a;
          cursor: pointer;
          svg {
            vertical-align: middle;
            margin-right: 5px;
            width:17px;
          }
          &:hover {
            background: lighten($サブカラー,10%);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          }
        }
      }
    }
  }

  .MuiDialogActions-root {
    button {
      width: 160px;
      height: 32px;
      margin:8px 45px;
      border-radius: 5px;
      background: $アクセントカラー;
      border: none;
      font-size: 18px;
      color: #fff;
      &:hover {
        background: lighten($アクセントカラー,10%);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }

  
}