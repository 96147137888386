@import "valiable";

.dekigoto {
  .loading {
    display: flex;
    min-height: 50vh;
    justify-content: center;
    align-items: center;

  }
  .tableBase {
    background-color: #ffffff;
    box-shadow: 0px 3px 5px #00000038;
    position: relative;

    padding: 18px;
    &.nopadding {
      padding: 0;
    }

    table {
      width: 100%;
      border-collapse:collapse;
      thead {
        background: $メインカラー;
        color:#ffffff;
        font: normal normal bold 18px/27px Meiryo;
        text-align: left;
        tr {
          table-layout: fixed;
          th {
            padding:3px 13px;

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5)
            {
              width:150px;
            }
            &:nth-child(3)
            {
              width:250px;
            }
          }
        }
      }
      tbody {
        border: 1px solid #B2BAC2;
        tr {
          table-layout: fixed;
          background: #F1F4F8;
          &:nth-child(2n) {
            background: #FFFFFF;
          }
          cursor: pointer;

          &:hover {
            box-shadow: 0px 3px 5px #00000038;
            position: relative;
            z-index:100;
          }
          td {
            padding:13px;
            color: $淡文字;
          }
        }
      }
    }

    .selects {
      position: absolute;
      right:18px;
      top:23px;
      
      .MuiFormControl-root {
        margin-left:10px;
      }

    }
  }

  &>.date {
    font: normal normal bold 20px/30px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    color: $文字;
    &.disabled {
      color: #DBE2E8;
    }
    @media print {
      display: none;
    }
  }
  &>.title {
    @media print {
      display: none;
    }
    width: 970px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    font: normal normal bold 24px/60px Meiryo;
    letter-spacing: 0px;
    padding: 0 23px;
    overflow: hidden;
    color: $文字;
    &.disabled {
      color: #DBE2E8;
    }
  }
  .tabs {
    @media print {
      display: none;
    }
    margin-top: 20px;
    display: flex;
    .tab {
      width: 250px;
      height: 60px;
      box-shadow: 0px 3px 5px #00000038;
      border-radius: 10px 10px 0px 0px;
      margin-right: 15px;
      background-color: #DBE2E8;
      text-align: center;
      font: normal normal bold 20px/60px Meiryo;
      color: #B2BAC2;

      &.preview {
        margin-left: auto;
        margin-right: 0;
      }
      &.active {
        background-color: $メインカラー;
        color: #FFFFFF;
        &.preview {
          background-color: $アクセントカラー;
        }
      }
    }
  }

  .mainArea {
    position: relative;
    padding:40px;
    border-top: 5px solid $メインカラー;
    &.preview {
      border-top: 5px solid $アクセントカラー;
      padding:32px 50px;

      .buttonArea {
        @media print {
          display: none;
        }
        position: absolute;
        right:28px;
        top:32px;

        button {
          width:130px;
          height: 48px;
          margin-left: 20px;
        }
      }
      .titleArea {
        padding:0;
        margin-right:300px;
        .title {
          padding:0;
          background: none;
          border: none;
          border-radius: 0;
          box-shadow: none;
          width:100%;
          height:auto;
          font: normal normal bold 24px/36px Meiryo;
        }
        .date {
          padding:0;
          font: normal normal bold 16px/24px Meiryo;
        }
      }
      .dataArea {
        position: relative;
        dl {
          display: flex;
          flex-wrap: wrap;
          dt , dd{
            font: normal normal normal 18px/27px Meiryo;
            margin: 0 0 7px 0;
          }
          dt {
            width: 108px;
            position: relative;
            &::after {
              content:':';
              text-align: right;
              position: absolute;
              display: block;
              right: 3px;
              top:0;
            }
          }
          dd {
            width: calc(100% - 108px);
            padding-left: 5px;
            
            span {
              white-space:nowrap;
              margin-right: 1em;
            }
          }
        }
        #dataAreaCheck {
          display: none;
        }
        #dataAreaCheck + dl{
          max-height:27px;
          overflow: hidden;
          transition: max-height .5s;
          @media print {
            max-height:1000px;
          }
        }
        #dataAreaCheck:checked + dl{
          height:auto;
          max-height: 500px;
          @media print {
            max-height:1000px;
          }
        }
        label {
          display: block;
          height: 30px;
          position: relative;
          &::after{
            display: block;
            position: absolute;
            bottom:10px;
            margin: 0 50%;
            content: "";
            width:15px;
            height: 15px;
            border-top: 2px solid #707070;
            border-left: 2px solid #707070;
            transform:rotate(225deg);
            transition: transform .3s;
            z-index: 50;
            @media print {
              transform:rotate(45deg);
            }
          }
        }
        #dataAreaCheck:checked + dl + label::after{
          transform:rotate(45deg);
        }
        &::after {
          display: block;
          position: absolute;
          bottom:0;
          content:"";
          height: 10px;
          width: calc(100% + 100px);
          display: block;
          border-bottom: 2px solid #DBE2E8;
          background-color: #fff;
          margin: 0 -50px 0 -50px;
          z-index: 10;
        }
        margin-bottom: 50px;
        padding-bottom: 10px;

      }
      .detailArea {
        position: relative;
        .textArea {
          float:left;
          font: normal normal normal 16px/24px Meiryo;
          white-space:pre-wrap;
          width:calc(100% - 740px);
        }
        .imageArea {
          float:right;
          width: 650px;
        }
      }
    }
    background: #FFFFFF;
    max-height: calc(100vh - 320px);
    @media print {
      max-height: none;
    }
    overflow-y: auto;
    @media print {
      overflow-y: visible;
    }

    p {
      margin:0;
      color: $文字;
      font: normal normal normal 20px/30px Meiryo;
    }
    dl {
      margin-top: 30px;
      dt {
        text-indent: none;
        margin:0;
        color: $文字;
        font: normal normal bold 20px/30px Meiryo;
        
        &.required {
          &::after {
            content:'必須';
            display: inline-block;
            text-align: center;
            font: normal normal bold 14px/22px Meiryo;
            color: #FFFFFF;
            background: #FF7272 0% 0% no-repeat padding-box;
            border-radius: 17px;
            width: 50px;
            height: 22px;
            margin-left: 20px;
            position: relative;
            top:-3px;
          }
        }
      }
      dd {
        text-indent: none;
        margin:5px 0 50px 0;
        color: $文字;
        font: normal normal normal 20px/30px Meiryo;
        

        input {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          font: normal normal normal 16px/20px Meiryo UI;
          color: #161616;
          padding: 10px;
          text-align: right;
          &[type=text] {
            width:720px;
            text-align: left;
          }
        }
        textarea {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height: 300px;
        }
        .list {
          background: $入力欄カラー;
          border:none;
          border-bottom:1px solid $文字;
          color: #161616;
          padding: 10px;
          width: 720px;
          min-height:80px;
        }

        &.editor {
          display: flex;
          input {
            width:310px;
            margin-right: 33px;
          }
        }

      }
    }

    button {
      background-color: #DBE2E8;
      border-radius: 5px;
      width: 100px;
      height: 48px;
      border:none;
      color: $文字;
      font: normal normal bold 20px/30px Meiryo;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover{
        background-color: #DBE2E880;
        box-shadow: 0px 2px 2px #00000038;

      }
    }

    .buttons {
      text-align: right;
      button {
        background: $アクセントカラー;
        width: 160px;
        color: #FFFFFF;

        &:hover{
          background-color: lighten($アクセントカラー, 5%);
        }
        &:disabled {
          background-color: desaturate($アクセントカラー, 50%);
          box-shadow: none;
        }
      }
    }

  }

  .searchConditions {
    display:flex;
    flex-wrap: wrap;
    dt {
      width:240px;
      background-color: $サブカラー;
      font-size:20px;
      padding:15px 20px;
      border-bottom: 1px solid $ボーダーカラー;
    }
    dd {
      width:calc(100% - 240px);
      margin:0;
      padding:15px 20px;
      //background-color: #ccc;
      border-bottom: 1px solid $ボーダーカラー;

      .history {
        display: inline-block;
        text-decoration: underline;
        font-size: 14px;
        color: $文字;
        margin-top:10px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .buttonPos {
    //position: absolute;
    //bottom:20px;
    //right:20px;
    text-align: right;
    margin-top:20px;
  }
  .MuiTypography-root {
    color:$淡文字;
  }

  .tile {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.22);
    margin-bottom:24px;
    border-top: 4px solid $メインカラー;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    .imageArea {
      .fileDataView {
        .thumbnail {
          div {
            img {
             width:320px;
             height: auto;
             margin-bottom: 0;
            }
          }
        }
      }
    }
  
    .dataArea {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .dataAreaMain {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 25px;
        .date {
          font-size: 16px;
        }
        .title {
          font-size: 20px;
          text-align: justify;
          cursor: pointer;
        }
        .company {
          margin-left: 5em;
          text-indent: -5em;
          text-align: justify;
        }
        .mdate {
          display: inline-block;
        }
        .user {
          margin-left: 2em;
          display: inline-block;
        }
      }
      .dataAreaSub {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 25px;
        div {
          width: 310px;
          text-align: end;
          .media {
            padding-top: 10px;
            text-align: end;
          }
          button {
            width: 110px;
            height: 30px;
            border: none;
            margin-left: 20px;
            border-radius: 5px;
            background: #dbe2e8;
            font-size: 16px;
            &:hover{
              background-color: #DBE2E880;
              box-shadow: 0px 2px 2px #00000038;
            }
          }
        }
      }
    }
  }

  .dekigotoListImage {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-around;

    .image {
      width: 350px;
      height:262px;
      margin-left: 35px;
      margin-bottom: 25px;
      position: relative;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);


      .imageArea {

        position: relative;

        button {
          width: 210px;
          height: 32px;
          border-radius: 5px;
          border: none;
          background: #dbe2e8;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          position: absolute;
          right: 10px;
          top:10px;
          z-index: 100;
          font-size: 16px;
          &:hover{
            background-color: lighten(#dbe2e8, 5%);
            box-shadow: 0px 2px 2px #00000038;
          }
      }
  
        img {
          width: 350px;
          height:262px;
          object-fit: cover;
        }
      }
      .title {
        width: 350px;
        min-height: 57px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom:0;
        padding: 6px 11px;
        font-size: 14px;
        text-align: justify;
        color: #fff;
        cursor: pointer;
      }
    }

  }
}

.dekigotodetail {
  .buttons {
    @media print {
      display: none;
    }
    margin-top:-10px;
    display: flex;
    justify-content:space-between;
  }
  .detail {
    border-top:5px solid $アクセントカラー;
    margin-top: 16px;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px #00000038;
    position: relative;
    @media print {
      margin-top: 0;
      box-shadow: none;
    }
  }
  .mainArea {
    max-height: calc(100vh - 200px);
  }
}



.dekigotoFile {
  .uploadText {
    margin:50px -40px 0 -40px;
    padding:40px;
    border-top: 2px solid #DBE2E8;
    font: normal normal normal 20px/30px Meiryo;

    span {
      font-weight: bold;
    }
  }
}

