@import "valiable";

.menu {
  @media print {
    display: none;
  }
  width: 300px;
  height: calc(100vh - 80px);
  background: #FAFBFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000038;


  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding:15px 0;
    text-indent: 0;
    li {
      padding: 5px 20px;
      a {
        display: block;
        text-decoration: none;
        top: 100px;
        left: 20px;
        width: 260px;
        height: 65px;
        border-radius: 33px;

        text-align: left;
        font: normal normal bold 20px/30px Meiryo;
        line-height: 65px;
        padding:0 20px;
        color: $文字;

        &.active {
          background: #DBE2E8;
        }
        &:hover {
          background: #DBE2E880;
        }

      }
    }
  }
}