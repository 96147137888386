.companyDialog {
  ul {
    margin:0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      list-style: none;
      text-indent: 0;
      white-space: nowrap;
      margin-right:2em;
      width:400px;
    }
  }
}